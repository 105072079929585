import React from "react";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";

interface HomeProps {
  setSelectedEvent: (event: any) => void;
}

const Home: React.FC<HomeProps> = ({ setSelectedEvent }) => {
  const event = {
    id: 1,
    title: "Хэллоуин Концерт",
    date: "October 26",
    description:
      "Хэллоуин — это праздничное мероприятие, полное драйва и сюрпризов.",
    image:
      "https://s3-alpha-sig.figma.com/img/b964/3ad6/a113519b8479c3cde82016babb43aa87?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tc0Na0~w16n9qas4SlWMz95ARQE4sRf~oBkTRiB9hebWDQIDB~TgZ1T47ihMI6bd-GiIMSi5yrCtRZnQmnfNDzSe3aThZTIdxY1JRK5l~FmKJwv0arPlESwt8uBhl32omjLchoV6263rJO8nMxU6bnKF4TX3YukdXUPywYrJi0P~xfv3pcdgx-nLTbMsRckkTzC6NSpN1jh-hLJfDTK8XL0f5igc2vCE9-y-we2B7fOcJsl8VmH0pclSJZiMr7s54ccFyd4izSSR~I~R6aXCc-ho59ePsw6S260JV4eXJOhaAdDiW9kL4mLG6Air5NGZCGhCfULmVQpghXf-x6lF6g__",
    src: "https://www.youtube.com/watch?v=nW4ZcJvJoXs",
  };

  const handleBuyTicket = () => {
    setSelectedEvent(event);
  };

  return (
    <div className="home-container">
      <main>
        <h1 className="logo">DAFT</h1>
        <section className="upcoming-event">
          <h2 className="next-event">Ближайшие мероприятия</h2>
          <div className="event-card">
            <img src="123123.png" alt="Хэллоуин Мероприятие" />
            <div className="event-info">
              <strong>October - 26 HALLOWEEN</strong>

              <p>
                Хэллоуин вечеринка — это праздничное мероприятие, посвященное
                Дню всех святых, которое проводится в конце октября. Гости
                обычно наряжаются в костюмы, связанные с тематикой ужаса,
                мистики или фантастики: вампиры, ведьмы, зомби и прочие
                персонажи.
              </p>
              <a
                href="https://www.youtube.com/watch?v=oHg5SJYRHA0"
                target="_blank"
              >
                <button onClick={handleBuyTicket} className="buy-ticket-btn">
                  Купить Билет
                </button>
              </a>
            </div>
          </div>
        </section>
        <section>
          <h2 className="merch-logo">Мерч</h2>
          <div>
            {" "}
            <Carousel />
          </div>
        </section>
        <section>
          <div>
            <div className="make-logo-container">
              <div className="make-logo-background">
                <h2 className="make-logo">Make some noise</h2>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-socials">
          <h2 className="footer-title">О компании</h2>
          <p>
            DAFT - это клубные мероприятия и <br></br>организация подростковых
            ивентов различных форматов. На <br></br>каждом мероприятии мы
            стремимся удивить вас, представив <br></br>что-то новое, что вы еще
            не видели. Мы поддерживаем ЗОЖ и <br></br>
            показываем подросткам, что такое клуб – не только <br></br>
            разрушение здоровья, но и весёлое время препровождение. <br></br>
            Сравниваясь <br></br> с образом клубов по телевизору, мы
            демонстрируем, что клуб <br></br> – это не всегда вредно для
            здоровья, а забавное время <br></br> препровождение. <br></br>Мы
            ждем именно тебя на нашем CLUB SHOW.
          </p>
          <nav className="footer-navbar">
            <ul>
              <li className="inst">
                <a href=""></a>
              </li>
              <li className="vk">
                <a href=""></a>
              </li>
              <li className="tg">
                <a href=""></a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Home;
