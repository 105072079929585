import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Merch from "./components/Merch";
import EventDetails from "./components/EventDetails";
import "./App.css";
const App: React.FC = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  return (
    // <div className="App">
    //   <div className={`logo-container ${loading ? "loading" : "loaded"}`}>
    //     <h1 className="logoo">DAFT</h1>
    //   </div>
    //   {!loading && (
    <div className="content">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home setSelectedEvent={setSelectedEvent} />}
          />
          <Route path="/merch" element={<Merch />} />
          <Route
            path="/events/:id"
            element={<EventDetails selectedEvent={selectedEvent} />}
          />
        </Routes>
      </Router>
    </div>
    //   )}
    // </div>
  );
};

export default App;
