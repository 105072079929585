import React from 'react';

const Merch: React.FC = () => {
  return (
    <div className="merch-container">
      <h2>Мерч скоро появится!</h2>
    </div>
  );
};

export default Merch;
