import React from "react";
import { useParams } from "react-router-dom";

interface EventDetailsProps {
  selectedEvent: {
    id: number;
    title: string;
    date: string;
    description: string;
    image: string;
    src: string;
  } | null;
}
const event = {
  id: 1,
  title: "Хэллоуин Концерт",
  date: "October 26",
  description:
    "Хэллоуин — это праздничное мероприятие, полное драйва и сюрпризов.",
  image:
    "https://s3-alpha-sig.figma.com/img/b964/3ad6/a113519b8479c3cde82016babb43aa87?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tc0Na0~w16n9qas4SlWMz95ARQE4sRf~oBkTRiB9hebWDQIDB~TgZ1T47ihMI6bd-GiIMSi5yrCtRZnQmnfNDzSe3aThZTIdxY1JRK5l~FmKJwv0arPlESwt8uBhl32omjLchoV6263rJO8nMxU6bnKF4TX3YukdXUPywYrJi0P~xfv3pcdgx-nLTbMsRckkTzC6NSpN1jh-hLJfDTK8XL0f5igc2vCE9-y-we2B7fOcJsl8VmH0pclSJZiMr7s54ccFyd4izSSR~I~R6aXCc-ho59ePsw6S260JV4eXJOhaAdDiW9kL4mLG6Air5NGZCGhCfULmVQpghXf-x6lF6g__",
  src: "https://www.youtube.com/watch?v=nW4ZcJvJoXs",
};

const EventDetails: React.FC<EventDetailsProps> = ({ selectedEvent }) => {
  const { id } = useParams();

  if (!selectedEvent || selectedEvent.id.toString() !== id) {
    return <div>Мероприятие не найдено</div>;
  }

  return (
    <section>
      <h1>DAFT</h1>
      <h2>Описание мероприятия</h2>
      <div className="event-details-container">
        <h2>{selectedEvent.title}</h2>
        <img src={selectedEvent.image} alt={selectedEvent.title} />
        <p>
          <strong>Дата:</strong> {selectedEvent.date}
        </p>
        <p>
          <strong>Описание:</strong> {selectedEvent.description}
        </p>
        <a href={selectedEvent.src}>
          <button>купить билет</button>
        </a>
      </div>
    </section>
  );
};

export default EventDetails;
